<template>
	<v-container fluid style="width: 95%">
		<!-- <v-layout>
			<v-flex>
				<h3 class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue">Leave</h3>
			</v-flex>
			<v-flex class="text-right py-3 px-3">
				<v-btn class="custom-grey-border custom-bold-button" @click="goBack">
					<v-icon small left>mdi-keyboard-backspace</v-icon>
					Back
				</v-btn>
			</v-flex>
		</v-layout> -->
		<perfect-scrollbar :options="{ suppressScrollX: true }" class="mt-2 custom-box-top-inner-shadow">
			<v-card flat class="custom-setting-height custom-grey-border remove-border-radius">
				<v-card-title class="headline grey lighten-4">
					<v-row>
						<v-col md="6" class="py-0 my-auto">
							<div class="fw-700 text-capitalize">Claim Category</div>
						</v-col>
						<template>
							<v-col md="6" class="text-right py-0">
								<v-icon class="mr-2" tag="span" color="red darken-4" @click="AddLeave" size="34"
									>mdi-plus-box</v-icon
								>
								<v-btn
									color="blue darken-4"
									class="white--text my-auto"
									depressed
									tile
									@click="updateOrcreate"
									:disabled="pageLoading || !formValid"
								>
									<span class="font-size-16 font-weight-600">Save</span>
								</v-btn>
							</v-col>
						</template>
					</v-row>
				</v-card-title>
				<v-card-text class="px-6 py-3 font-size-16">
					<v-form
						ref="claim_category"
						v-model.trim="formValid"
						lazy-validation
						@submit.stop.prevent="updateOrcreate"
					>
						<v-row>
							<v-col md="5"><label class="fw-600 fs-16">Claim Type</label></v-col>
							<v-col md="2"><label class="fw-600 fs-16">Max Amount</label></v-col>
							<v-col md="3"><label class="fw-600 fs-16 required">Interval</label></v-col>
							<v-col md="2" class="ps-6"><label class="fw-600 fs-16">Action</label></v-col>
						</v-row>

						<div class="overflow-y-auto overflow-x-hidden" style="height: calc(100vh - 202px)">
							<v-row class="my-1" v-for="(type, index) in claimList" :key="index">
								<v-col md="5" class="">
									<!-- <label class="input-label">Claim Type</label> -->
									<v-text-field
										hide-details
										:ref="`glc_claim_category_${index}`"
										outlined
										class="textCapitalize"
										placeholder="Claim Type"
										v-model="type.category"
										:rules="[vrules.required(type.category, 'Claim Type')]"
										:class="{
											required: !type.category,
										}"
										:disabled="!type.activated || Boolean(+type.is_permanent)"
									>
									</v-text-field>
								</v-col>
								<v-col md="2" class="">
									<!-- <label class="input-label">Max Amount</label> -->
									<v-text-field
										hide-details
										outlined
										type="Number"
										placeholder="Max Amount"
										v-model="type.maxAmount"
										min="0"
										max="999"
										:rules="[vrules.required(type.maxAmount, 'Max Amount')]"
										:class="{
											required: !type.maxAmount,
										}"
										:disabled="!type.activated"
									>
									</v-text-field>
								</v-col>
								<v-col md="3" class="">
									<!-- <label class="input-label fw-500 required">Interval</label> -->
									<v-autocomplete
										:items="intervals"
										:menu-props="{ bottom: true, offsetY: true }"
										outlined
										clearable
										height="30px"
										hide-details
										v-model="type.duration_type"
										item-text="title"
										item-value="value"
										placeholder="Select Interval"
										:rules="[vrules.required(type.duration_type, 'Interval')]"
										:class="{
											required: !type.duration_type || type.duration_type == 0,
										}"
									>
										<template #item="{ item }">
											<div class="text-uppercase pt-1 fw-500">{{ item.title }}</div>
										</template>
									</v-autocomplete>
								</v-col>
								<v-col md="2" class="ps-6">
									<v-tooltip top content-class="custom-top-tooltip">
										<template #activator="{ on, attrs }">
											<v-icon
												v-on="on"
												v-bind="attrs"
												v-if="!type.is_permanent"
												@click="deleteCommonList(index, type.id)"
												color="red darken-1"
												class="mr-2"
												dark
											>
												mdi-trash-can
											</v-icon>
										</template>
										<span>Delete {{ type.category }}</span>
									</v-tooltip>

									<v-tooltip top content-class="custom-top-tooltip">
										<template #activator="{ on, attrs }">
											<v-icon
												v-on="on"
												v-bind="attrs"
												:color="btnStatus.get(type.activated)?.color"
												dark
												@click="deleteCommon(type.id, index, type.activated)"
												v-if="type.id && !type.is_permanent"
											>
												{{ btnStatus.get(type.activated)?.icon }}
											</v-icon>
										</template>
										<span> {{ btnStatus.get(type.activated)?.title }} {{ type.category }}</span>
									</v-tooltip>
								</v-col>
								<!-- <v-col md="12" v-if="index != claimList.length - 1">
									<v-divider class="my-1"></v-divider>
								</v-col> -->
							</v-row>
							<template v-if="claimList.length < 1">
								<p class="m-0 row-not-found text-center">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There are no {{ type }} at the moment.
								</p>
							</template>
						</div>
					</v-form>
				</v-card-text>
			</v-card>

			<DeleteTemplate
				type="Claim Category"
				v-if="deleteDialog"
				delete-text=""
				@success="deleteClaim"
				@close="deleteDialog = [false]"
				:delete-dialog="deleteDialog && deleteDialog[0]"
				delete-note="delete claim category"
			>
			</DeleteTemplate>
		</perfect-scrollbar>
	</v-container>
</template>

<script>
import { POST, GET, DELETE, PATCH } from "@/core/services/store/request.module";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import { map } from "lodash";
import { cloneDeep } from "lodash";
export default {
	name: "leave-setting",
	components: { DeleteTemplate },
	data() {
		return {
			customerId: 0,
			formValid: true,
			pageLoading: false,
			claimList: [
				{
					category: null,
					maxAmount: null,
					duration_type: null,
				},
			],
			intervals: [
				{
					title: "Yearly",
					value: "yearly",
				},
				{
					title: "Monthly",
					value: "monthly",
				},
			],
			btnStatus: new Map([
				[1, { icon: "mdi-cancel", title: "Disable", color: "red darken-2" }],
				[0, { icon: "mdi-check-circle", title: "Enable", color: "green darken-2" }],
				[true, { icon: "mdi-cancel", title: "Disable", color: "red darken-2" }],
				[false, { icon: "mdi-check-circle", title: "Enable", color: "green darken-2" }],
			]),
			deleteDialog: [false],
		};
	},
	methods: {
		deleteClaim() {
			const id =
				(Array.isArray(this.deleteDialog) && this.deleteDialog.length > 1 && this.deleteDialog[1]) || 0;
			this.$store
				.dispatch(DELETE, {
					url: `setting/claim/${id}`,
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Success ! Claim Created successfully.",
						},
					]);
					this.getClaimDays();
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.deleteDialog = [false];
				});
		},
		updateOrcreate() {
			const formErrors = this.validateForm(this.$refs["claim_category"]);

			this.$refs["claim_category"].validate();

			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}

			if (!this.$refs["claim_category"].validate()) {
				return false;
			}

			const _claimList = cloneDeep(this.claimList);

			if (this.deleteDialog.length > 1) {
				const deleteIndex = this.deleteDialog[1];
				if (typeof deleteIndex == "number") {
					_claimList.splice(deleteIndex, 1);
				}
			}

			this.$store
				.dispatch(POST, {
					url: "setting/claim",
					data: { categories: _claimList },
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Success ! Claim Created successfully.",
						},
					]);
					this.getClaimDays();
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.deleteDialog = [false];
				});
		},

		AddLeave() {
			const idx = this.claimList.length;
			this.claimList.push({
				category: null,
				maxAmount: null,
				activated: 1,
				duration_type: "monthly",
			});
			setTimeout(() => {
				this.$refs[`glc_claim_category_${idx}`][0].focus();
			}, 0);
		},
		deleteCommon(categoriesid, index, status = "") {
			const _status = status || status == 1 ? "inactive" : "active";
			this.disableSettingLeave(categoriesid, index, _status);

			// if (categoriesid) {
			// 	this.deleteSettingLeave(categoriesid, index);
			// } else {
			// 	this.deleteLeaveDay(index);
			// }
		},
		deleteCommonList(index, id) {
			if (this.claimList.length > 1) {
				if (id) {
					this.deleteDialog = [true, id];
				} else {
					this.claimList.splice(index, 1);
				}
			}
		},
		/* deleteLeaveDay(index) {
			if (this.claimList.length > 1) {
				this.claimList.splice(index, 1);
			}
		}, */
		/* deleteSettingLeave(categoriesid, index) {
			this.$store
				.dispatch(DELETE, {
					url: "setting/claim/" + categoriesid,
				})
				.then(() => {
					if (this.claimList.length > 0) {
						this.claimList.splice(index, 1);
					}
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Deleted ! Claim successfully.",
						},
					]);
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		}, */

		disableSettingLeave(categoriesid, index, status = "") {
			this.$store
				.dispatch(PATCH, {
					url: "setting/claim-category-status/" + categoriesid + "/?status=" + status,
				})
				.then((response) => {
					//console.log(response);
					this.claimList = response;

					if (this.claimList.length > 0) {
						console.log(index);
						//this.claimList.splice(index, 1);
					}
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Claim Category Updated successfully.",
						},
					]);
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getClaimDays() {
			this.pageLoading = true;
			this.$store
				.dispatch(GET, {
					url: "setting/claim",
				})
				.then((data) => {
					if (Array.isArray(data) && data.length) {
						this.claimList = map(data, (row) => {
							return { ...row, category: row.category.replace("-", " ") };
						});
					} else {
						this.claimList = [];
					}
				})
				.catch((error) => {
					this.logError(error);
					/* this.goBack(); */
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	/* getStaffSetting() {
        
        this.$store
          .dispatch(GET, { url: "setting/engineer" })
          .then(({ data }) => {
            this.setting = data;
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.pageLoading = false;
          });
      },
      updateStaffSetting() {
        
        this.pageLoading = true;
        this.$store
          .dispatch(PATCH, { url: "setting/engineer", data: this.setting })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.pageLoading = false;
          });
      },*/
	mounted() {
		this.getClaimDays();
	},
	beforeMount() {
		this.customerId = this.$route.params.id;
	},
};
</script>
